<template>
  <div class="sm:flex block flex-wrap gap-2 items-center relative justify-between mt-6">
    <p class="mr-4">
      <span class="font-semibold">{{$t('Total: ')}}</span>
      <span>{{Number(totalRows).toLocaleString('en')}}</span>
    </p>
    <div class="flex">
      <button v-if="!noOptions" class="actions-dropdown relative mr-4">
        <div class="m-auto flex text-base items-center justify-between actions-btn">
          <span class="mr-2" id="pageSpan">{{maximumItems + $t(' / page')}}</span>
          <feather-icon id="pageIcon" class="w-5 h-5" icon="ChevronDownIcon"></feather-icon>
        </div>
        <div class="dropdown-list hidden-list" id="paginationList">
          <button @click="changePageSize(item)" v-for="item in pageSizes" :key="item.index">
            <span>{{ item }}</span>
          </button>
        </div>
      </button>
      <vs-pagination :total="Math.ceil(totalRows * 1.0 / maximumItems)" v-model="currentPage"></vs-pagination>
    </div>
  </div>
</template>

<script>
export default {
  props: ['totalRows', 'currentPage', 'noOptions', 'maximumItems'],
  data () {
    return {
      pageSizes: [10, 25, 50]
    }
  },
  watch: {
    currentPage (val) {
      this.$emit('currentPage', val)
    }
  },
  methods: {
    changePageSize (val) {
      this.$emit('changePageSize', val)
    }
  }
}
</script>